const yohoContracts: Record<number, string> = {
  1: '0xc8d1acd9aC1116F8450b375be2A3730F43e916d4', // mainnet
  // 5: '0x07865c6e87b9f70255377e024ace6630c1eaa37f', // goerli
  // 10: '0x7f5c764cbc14f9669b88837ca1490cca17c31607', // optimism
  // 56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', // bnb
  // 137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // polygon
  // 42161: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', // arbitrum
  // 43114: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // avalanche
  // 42170: '0x750ba8b76187092B0D1E87E28daaf484d1b5273b', // arbitrum nova
  // 59144: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff', // linea
  // 80001: '0x0fa8781a83e46826621b3bc094ea2a0212e71b23', // mumbai
  // 8453: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', // base
  // 84531: '0xf175520c52418dfe19c8098071a252da48cd1c19', // base goerli
  // 534353: '0xA0D71B9877f44C744546D649147E3F1e70a93760', // scroll testnet
}

export default yohoContracts
